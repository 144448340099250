import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { provideHttp } from '@core/interceptors/http-config.provider';
import { importProvidersFrom } from '@angular/core';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { provideYandexMapsConfig } from '@core/configs/yandex-map.config';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { appRoutes } from './app/app.routes';
import { provideTransloco } from '@core/transloco/transloco.provider';
import { provideIcons } from '@core/icons/icons.provider';
import { provideFuse } from '@fuse/fuse.provider';
import FUSE_CONFIG from '@core/configs/fuse.config';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from '@shared/utils/custom-mat-paginator';
import { DATE_CONFIGS } from '@core/configs/date.config';
import { NamePipe } from '@shared/pipes/name.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

bootstrapApplication(AppComponent, {
  providers: [
    provideHttp(),
    importProvidersFrom(AngularYandexMapsModule.forRoot(provideYandexMapsConfig()), NgxMatNativeDateModule),
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideTransloco(),
    provideIcons(),
    provideEnvironmentNgxMask({
      validation: false,
    }),
    provideFuse(FUSE_CONFIG),
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    ...DATE_CONFIGS,
    NamePipe,
    MatSnackBar,
    // TODO: ROMOVE OR USE. COMMENTED BECAUSE OF STRONG CACHE
    // source for service worker implementation: https://blog.angular-university.io/angular-service-worker/
    // provideServiceWorker('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // enabled: true,
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
}).catch(err => console.error(err));
