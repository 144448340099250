import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor() {}
  listenOnUpdate = new ReplaySubject();
}
